// @flow
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import idx from 'idx';
import { Location, navigate } from '@reach/router';
import queryString from 'query-string';
import { CircularProgress, Modal } from '@material-ui/core';

import {
  getHubSpotSubscriptions,
  addToHubSpotUnsubscribeGroup,
} from '../../../utils/email';

import Layout from '../../../components/Layout/Layout';
import HeaderEmail from '../../../components/Email/HeaderEmail';
import FooterEmail from '../../../components/Email/FooterEmail';

import styles from './unsubscribe.module.scss';

const Unsubscribe = ({ location }) => {
  const { email } = (idx(location, (_) => _.search) &&
    queryString.parse(location.search)) || { id: '', email: '' };
  const [isLoading, setIsLoading] = useState(false);

  const unsubscribeFromHubSpot = async (_email) => {
    const {
      data: { subscriptions },
    } = await getHubSpotSubscriptions();

    const promotionalEmailSub = subscriptions.subscriptionDefinitions.find(
      (sub) => sub.name === 'Promotional email'
    );

    await addToHubSpotUnsubscribeGroup(_email, promotionalEmailSub.id);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      await unsubscribeFromHubSpot(email);

      navigate('/email/unsubscribe/success');
    } catch (error) {
      navigate('/email/error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!email) {
      navigate('/');
    }
  }, []);

  return (
    <Layout hasNoHeader hasNoFooter>
      <div className={styles.wrapper}>
        <HeaderEmail className={styles.header} />
        <div className={styles.content}>
          <h2 className={styles.heading}>
            これまでのご購読、
            <br />
            ありがとうございました。
          </h2>
          <p className={styles.p}>
            下記のボタンをクリックすると、プロモーションメールの配信が停止されます。
          </p>
          <div className={styles.note}>
            <img
              src={require('../../../images/icon-unsubscribe-note.svg')}
              width="26px"
              alt="Unsubscribe note icon"
              loading="lazy"
            />
            <p>
              ご請求やお支払いに関するメールは引き続き送信されますのでご安心ください。
            </p>
          </div>
          <button
            type="button"
            className={cx(styles.button, 'email', 'btn')}
            onClick={onSubmit}
          >
            配信を停止する
          </button>
        </div>
        <FooterEmail className={styles.footer} />
      </div>
      <Modal open={isLoading} className={styles.loadingModal}>
        <CircularProgress size={85} />
      </Modal>
    </Layout>
  );
};

export default () => (
  <Location>{({ location }) => <Unsubscribe location={location} />}</Location>
);
