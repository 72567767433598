import axios from 'axios';
import { HUBSPOT_EMAIL_GET_SUBSCRIPTION_TYPES_ENDPOINT, HUBSPOT_EMAIL_UNSUBSCRIBE_ENDPOINT } from '../constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const errorHandler = (err) => {
  console.log(err);
  throw err;
};

// Hubspot unsubscribe
export const getHubSpotSubscriptions = async () => {
  try {
    return await axios.get(HUBSPOT_EMAIL_GET_SUBSCRIPTION_TYPES_ENDPOINT);
  } catch (error) {
    return errorHandler(error);
  }
};

// Hubspot unsubscribe
export const addToHubSpotUnsubscribeGroup = async (
  email,
  subscriptionTypeId
) => {
  try {
    return await axios.post(
      HUBSPOT_EMAIL_UNSUBSCRIBE_ENDPOINT,
      {
        email,
        subscriptionStatuses: [
          {
            id: subscriptionTypeId,
            subscribed: false,
            optState: 'OPT_OUT',
          },
        ],
      },
      { headers }
    );
  } catch (error) {
    return errorHandler(error);
  }
};

export default {
  getHubSpotSubscriptions,
  addToHubSpotUnsubscribeGroup,
};
